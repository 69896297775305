<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="row content-header">
        <div class="content-header-left mb-2 col-md-9 col-12">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-left pr-1 mb-0">Импорт товаров</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Поиск товаров H&M на сайте производителя</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-row form-group">
                        <label class="col-md-4 col-form-label" for="sku">Артикул</label>
                        <div class="col">
                          <input
                              id="sku"
                              type="text"
                              v-model="sku"
                              placeholder="Введите артикул"
                              @keyup="offset = 0"
                              class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                    <!-- submit and reset -->
                    <div class="col offset-md-4">
                      <button type="submit" v-if="offset < 4" @click="search" class="btn mr-1 btn-primary">
                        Искать ({{ offset*10 }} - {{ offset*10+10 }})
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <h2 class="content-header-title ">Результаты поиска</h2>
            </div>
            <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
              <div class="col-12">
                <div class="row">
                  <div class="col-4"  v-for="(product, index) in results" :key="index">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title">{{ product.name }} H&M</h4>
                        <h6 class="card-subtitle text-muted mb-2">Категория: {{ product.category }}</h6>
                        <img :src="product.images[0]" class="mb-2 img-fluid">
                        <p class="card-text small">
                          <b>Состав:</b><br>
                        </p>
                        <div class="row mb-2">
                          <div class="col-12 small" v-for="(material, index) in product.materials" :key="index">
                            {{ material }}
                          </div>
                        </div>
                        <p class="card-text small"><b>Год модели:</b> {{ product.year }}</p>
                        <p class="card-text small"><b>Страна производства:</b> {{ product.country }}</p>
                        <p class="card-text small"><b>Описание</b><br> {{ product.description }}</p>
                        <router-link
                            v-if="!product.exists && $can('create', 'products')"
                            :to="{ name: 'product-create', params: { product: product } }"
                            class="btn btn-primary"
                        >
                          Добавить
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-overlay>
            <div class="col-12" v-if="!results.length">
              <div class="card no-results">
                <div class="card-body text-center">Нет результатов</div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { BOverlay } from 'bootstrap-vue';

export default {
  name: 'Import',
  components: { BOverlay },
  data() {
    return {
      sku: null,
      offset: 0,
      results: [],
      loading: false,
      selectedProduct: null,
    };
  },
  methods: {
    async search() {
      this.loading = true;
      if (this.sku.length < 7) {
        this.$toast.warning('Артикул должен содержать не менее 7 символов');
        return;
      }
      this.results = (await this.$api.products.vendorSearch({
        brand_id: 11,
        sku: this.sku,
        offset: this.offset,
      })).data;
      this.offset += 1;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.img-fluid {
  max-width: 50%;
  margin-left: 25%;
}
</style>
